import { format } from '@joint/plus'
import watermark from 'watermarkjs'
import { useDashboardStore } from '@/store/dashboard.js'
import SvgExportWorker from '@/workers/svg-export.js?worker'
import { useAppStore } from '@/store/app.js'

export const debounce = (fn, delay = 300) => {
  let timer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, delay)
  }
}

export const lowercaseKeys = (obj) => {
  const entries = Object.entries(obj)

  return Object.fromEntries(
    entries.map(([key, value]) => {
      return [key.toLowerCase(), typeof value === 'object' ? lowercaseKeys(value) : value]
    })
  )
}

export const priceFormatterOptions = {
  style: 'currency',
  maximumSignificantDigits: 8,
  maximumFractionDigits: 4
}

const ticks = {
  color: '#6F6F77',
  font: {
    family: 'Inter, sans-serif',
    size: 12
  }
}

export const chartjsOptions = {
  scales: {
    x: {
      ticks
    },
    y: {
      ticks
    }
  },
  plugins: {
    datalabels: {
      display: false
    },
    legend: {
      labels: {
        color: '#6F6F77',
        font: {
          family: 'Inter, sans-serif',
          size: 12
        }
      }
    }
  }
}

export const availableRoles = [
  {
    name: 'owner',
    description: 'Owners have full access to billing, views and team management.',
    permissions: ['edit-billing', 'edit-views', 'edit-teams', 'edit-cloud-accounts']
  },
  {
    name: 'editor',
    description: 'Editors have full access to billing and views but can’t manage teams.',
    permissions: ['edit-billing', 'edit-views']
  },
  {
    name: 'viewer',
    description:
      'Viewers can see billing, recommendations but won’t be able to modify elements or create views.',
    permissions: []
  }
]

export const exportDiagramImage = (
  diagram,
  whiteBackground,
  fileFormat,
  filenameOrCallback,
  displayWatermark,
  cb,
  encode = true
) => {
  const onFinish = (result) => {
    if (typeof filenameOrCallback === 'function') {
      filenameOrCallback(result)
    } else {
      const link = document.createElement('a')
      link.download = filenameOrCallback
      link.href = result
      link.click()
    }

    typeof cb === 'function' && cb()
  }

  let call = format.toSVG
  switch (fileFormat) {
    case 'image/svg':
      call = format.toSVG
      break
    case 'image/jpeg':
      call = format.toJPEG
      break
    case 'image/png':
      call = format.toPNG
      break
  }

  call(
    window.paper,
    (image) => {
      const finish = (end) => {
        let imageWithData = end

        if (fileFormat === 'image/svg' && encode) {
          imageWithData = `data:image/svg+xml;base64,${btoa(end)}`
        }

        if (!displayWatermark) {
          onFinish(imageWithData)
        } else {
          watermark([imageWithData, '/holori/watermark.svg'])
            .image(watermark.image.center(0.5))
            .then((img) => {
              onFinish(img.src)
            })
        }
      }

      if (fileFormat === 'image/svg') {
        const worker = new SvgExportWorker()
        worker.onmessage = (message) => {
          finish(message.data)
        }
        worker.postMessage([image])
      } else {
        finish(image)
      }
    },
    {
      backgroundColor: whiteBackground ? '#ffffff' : 'transparent',
      quality: 0.9, // For toJPEG
      padding: 30
    }
  )
}

/**
 * Format a tag to use `key` and `value`, from different formats of providers.
 * @param {Object} tag
 * @returns {{ key: string, value: any, date: string}}
 */
export const formatTag = (tag) => ({
  key: tag.key || tag.Key,
  value: tag.value || tag.Value,
  date: tag.date || tag.LastReportedAt
})

export const providerColors = {
  'Alibaba Cloud': '#FE6900',
  alibaba: '#FE6900',
  'Amazon Web Services': '#FF9900',
  aws: '#FF9900',
  'Microsoft Azure': '#36B9ED',
  azure: '#36B9ED',
  'Google Cloud Platform': '#FF3D00',
  gcp: '#FF3D00',
  Linode: '#1CB35C',
  linode: '#1CB35C',
  OVH: '#000E9C',
  ovh: '#000E9C',
  Oracle: '#F80102',
  oracle: '#F80102',
  oci: '#F80102',
  'Digital Ocean': '#0080FF',
  'digital ocean': '#0080FF',
  Scaleway: '#4F0699',
  scaleway: '#4F0699'
}

/**
 * Extracts the fulfilled values from Promise.allSettled.
 * @param {PromiseSettledResult<Awaited<any>>} results The array of promises results.
 * @returns {{allData: Array<Object>, allErrors: Array<String>}}
 */
export function extractFulfilledValues(results) {
  const allData = results.filter(({ status }) => status === 'fulfilled').map(({ value }) => value)
  const allErrors = results
    .filter(({ status, reason }) => status === 'rejected' && reason !== 'canceled')
    .map(({ reason }) => reason)
  return { allData, allErrors }
}

export function generateThumbnail(paper = window.paper) {
  return new Promise((resolve) => {
    format.toJPEG(
      paper,
      (dataURI) => {
        resolve(dataURI)
      },
      {
        width: 200,
        height: 130,
        quality: 0.8,
        padding: 10,
        useComputedStyles: false
      }
    )
  })
}

export const na = 'N/A'

export function getAWSNameFromTags(resourceID) {
  const dashboardStore = useDashboardStore()

  const tags = dashboardStore.resourcesData[resourceID]?.tags || []

  if (tags === 'loading') {
    return resourceID
  }

  if (Array.isArray(tags)) {
    const nameTag = tags.find(({ key }) => key.toLowerCase() === 'name')
    if (nameTag) {
      return nameTag.value
    }
  }

  return resourceID
}

export function addNetworkError(error) {
  const appStore = useAppStore()

  appStore.addNetworkError(error)
}

// Temporary.
window.showNetworkErrors = function() {
  const appStore = useAppStore()
  console.log(appStore.networkErrors)
}
